import { useEffect, useState } from "react";
import "./App.css";

import AOS from "aos";
import Logo from "./assest/logo.png";
import { Routes, Route, Navigate, Outlet, useNavigate, useLocation } from "react-router-dom";

import HeaderNav from "./component/header/Header";
import { getToken, messaging, onMessage } from "./firebase";
import { useAuth } from "./authentication/context/screenContext";
import { Avatar, FloatButton, List, message, notification } from "antd";
import { FetchUserProfile } from "./service/APIService";
import Allroutes from "./router/AllRoutes";
import { MdWhatsapp } from "react-icons/md";
import SubHeader from "./component/header/SubHeader";
import MobileBottomNavigationBar from "./pages/MobileBottomNavigationBar";
import Home from "./component/home/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  const { setFcmToken, token, CurrentUserInfo } = useAuth();
  const FcmAccessTokens = process.env.REACT_APP_FCM_TOKEN;
  console.log("FcmAccessTokens", FcmAccessTokens);
  const whatsappImage =
    "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/598px-WhatsApp_icon.png";
  const callIcon = "https://cdn-icons-png.freepik.com/512/10162/10162354.png";

  const ShowUserProfileDetails = async () => {
    try {
      await FetchUserProfile(token)
        .then((res) => {
          console.log("profile", res);
          if (res.status == 200) {
            CurrentUserInfo(res.data.data);
            localStorage.setItem("userData", JSON.stringify(res.data.data));
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!token) {
      ShowUserProfileDetails();
    }
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const requestPermissionAndToken = async () => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          const tokens = await getToken(messaging, {
            vapidKey: FcmAccessTokens,
          });
          console.log("FCM Token:", tokens);
          setFcmToken(tokens);
          // Send the token to your server to store it and use it to send notifications
        } else {
          console.error("Permission not granted for notifications");
        }
      } catch (error) {
        console.error("Error getting FCM token:", error);
      }
    };

    requestPermissionAndToken();
  }, []);

  useEffect(() => {
    const handleMessage = (payload) => {
      console.log("Message received. ", payload);
      notification.open({
        placement: "topRight",
        message: (
          <List itemLayout="horizontal">
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={Logo} />}
                title={<span>{payload?.notification?.title}</span>}
                description={payload?.notification?.body}
              />
            </List.Item>
          </List>
        ),
      });
      // Handle the notification here (e.g., show a toast, update UI)
    };

    const unsubscribe = onMessage(messaging, handleMessage);

    return () => {
      unsubscribe();
    };
  }, []);

  const phoneNumber = "919319737920"; // Replace with your phone number
  const message = "Welcome in Cutenails Service ";
  const handleWhatsAppShare = () => {
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };
  const NewphoneNumber = "+919319737920"; // Include the country code if necessary

  const handleCallClick = () => {
    window.location.href = `tel:${NewphoneNumber}`;
  };

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    console.log(location.pathname)
    if(location.pathname.includes('home')){
      navigate('/');
    }
  }, [navigate]);

  
  return (
    <div className="App">
      <SubHeader />
      <HeaderNav />
      <Routes>
        <Route path="/" element={<Home />} />

        {Allroutes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element}>
            {route.children &&
              route.children.map((childRoute, childIndex) => (
                <Route
                  key={childIndex}
                  path={childRoute.path}
                  element={childRoute.element}
                />
              ))}
          </Route>
        ))}
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
      </Routes>

      <div className="show_whatsapp_icon">
        <img src={whatsappImage} alt="WhatsApp" onClick={handleWhatsAppShare} />
      </div>
    </div>
  );
}

export default App;
