import axios from "axios";
// export const baseURL = "http://192.168.0.213:2200/api/user";
// export const baseURL = "http://43.205.136.79:2200/api/user";
// export const baseURL = "https://backend-api.cutenails.in/api/user";
 export const baseURL = process.env.REACT_APP_BASE_URL;
//export const baseURL = "http://localhost:2200/api/user";

export const paymentMode = [
  { key: 1, values: "CASH" },
  { key: 2, values: "ONLINE" },
  

];

export const slotLists = [
  {
    id: 1,
    time: "07:00 AM",
  },
  {
    id: 2,
    time: "08:00 AM",
  },
  {
    id: 3,
    time: "09:00 AM",
  },
  {
    id: 4,
    time: "10:00 AM",
  },
  {
    id: 5,
    time: "11:00 AM",
  },
  {
    id: 6,
    time: "12:00 PM",
  },
  {
    id: 7,
    time: "01:00 PM",
  },
  {
    id: 8,
    time: "02:00 PM",
  },
  {
    id: 9,
    time: "03:00 PM",
  },
  {
    id: 10,
    time: "04:00 PM",
  },
  {
    id: 11,
    time: "05:00 PM",
  },
  {
    id: 12,
    time: "06:00 PM",
  },
  {
    id: 13,
    time: "07:00 PM",
  },
  {
    id:14,
    time: "08:00 PM",
  },
];

//all urls
const limit=5;
export const Services = {
  loginUrl: `${baseURL}/login`,
  signupUrl: `${baseURL}/registeration`,
  verifyOtpUrl: `${baseURL}/verify-otp`,
  setOtpUrl: `${baseURL}/send-otp`,
  categoryListUrl: `${baseURL}/category-list`,
  fetchDashBannerUrl: `${baseURL}/dashboard-banner`,
  fetchCatBannerUrl: `${baseURL}/category-banner`,
  fetchLocationUrl: `${baseURL}/location-list`,
  AddLocationUrl: `${baseURL}/location`,
  fetchSettingUrl: `${baseURL}/general-setting`,
  orderPlaceUrl: `${baseURL}/order`,
  fetchOrderListUrl: `${baseURL}/order-list`,
  fetchUserProfileUrl: `${baseURL}/profile`,
  editUserProfileUrl: `${baseURL}/profile`,
  activePincodeUrl: `${baseURL}/active-pincode-list`,
  cancelOrderUrl: `${baseURL}/cancel-order`,
  addNewEnquiryUrl: `${baseURL}/add-new-enquiry`,
};

// login section

export const SignUpUser = async (body) => {
  const signup = await axios.post(Services.signupUrl, body);
  return signup;
};

export const VerifyOTPS = async (body) => {
  const otp = await axios.post(Services.verifyOtpUrl, body);
  return otp;
};
export const sendOTP = async (body) => {
  const otp = await axios.post(Services.setOtpUrl, body);
  return otp;
};

export const FetchCategoryList = async () => {
  const cat = await axios.get(Services.categoryListUrl);
  return cat;
};

export const FetchServiceList = async (current,categoryId) => {
  const service = await axios.get(
    `${baseURL}/category/${categoryId}/service-list?currentPage=${current}&itemsPerPage=${limit}`
  );
  return service;
};

export const FetchHeroBanner = async () => {
  const banenr = await axios.get(Services.fetchDashBannerUrl);
  return banenr;
};

export const FetchCategoryBanner = async () => {
  const banenr = await axios.get(Services.fetchCatBannerUrl);
  return banenr;
};

export const FetchSaveAddressList = async (token) => {
  const address = await axios.get(Services.fetchLocationUrl, {
    headers: { Authorization: token },
  });
  return address;
};

export const AddUserAddress = async (token, body) => {
  const address = await axios.post(Services.AddLocationUrl, body, {
    headers: { Authorization: token },
  });
  return address;
};

export const FetchGeneralSetting = async (token) => {
  const setting = await axios.get(Services.fetchSettingUrl, {
    headers: { Authorization: token },
  });
  return setting;
};

export const FetchTodayDiscount = async (token) => {
  const discount = await axios.get(
    `${baseURL}/discount-list?currentDate=${new Date()}`,
    {
      headers: { Authorization: token },
    }
  );
  return discount;
};

export const BookOurService = async (token, body) => {
  const order = await axios.post(Services.orderPlaceUrl, body, {
    headers: { Authorization: token },
  });
  return order;
};

export const FetchAllOrderList = async (token,current,filterStatus) => {
  const order = await axios.get(`${baseURL}/order-list?currentPage=${current}&itemsPerPage=${limit}&filterStatus=${filterStatus!=null? filterStatus : ""}`, {
    headers: { Authorization: token },
  });
  return order;
};

export const FetchAllOrderDetails = async (token, orderId) => {
  const order = await axios.get(`${baseURL}/order/${orderId}`, {
    headers: { Authorization: token },
  });
  return order;
};

export const FetchUserProfile = async (token) => {
  const user = await axios.get(Services.fetchUserProfileUrl, {
    headers: { Authorization: token },
  });
  return user;
};

export const UpdateUserProfile = async (token, body) => {
  const profile = await axios.put(Services.editUserProfileUrl, body, {
    headers: { Authorization: token },
  });
  return profile;
};

export const FetchActivePincodeList = async (token) => {
  const pincode = await axios.get(Services.activePincodeUrl, {
    headers: { Authorization: token },
  });
  return pincode;
};

export const CancelOrder = async (body, token) => {
  const cancel = await axios.post(Services.cancelOrderUrl, body, {
    headers: { Authorization: token },
  });
  return cancel;
};
export const addNewEnquiry = async (body) => {
  const otp = await axios.post(Services.addNewEnquiryUrl, body);
  return otp;
};